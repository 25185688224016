<template>
  <RightNowEditView disabled :right-now-id="rightNowId" />
</template>

<script>
import RightNowEditView from '@/views/rightNow/RightNowEditView.vue'

export default {
  name: 'RightNowView',
  components: {
    RightNowEditView
  },
  props: {
    rightNowId: {
      type: Number,
      required: false
    }
  }
}
</script>
