<script>
import RightNowNewView from '@/views/rightNow/RightNowNewView'
import ModuleView from '@/components/ModuleView.vue'

export default {
  name: 'RightNowEditView',
  extends: RightNowNewView,
  components: {
    appModuleView: ModuleView
  },
  props: {
    rightNowId: {
      type: Number,
      required: false
    }
  },
  mounted () {
    this.getRightNow()
  }
}
</script>
